const SET_POLICY_SEARCH = 'SET_POLICY_SEARCH'
const SET_LIST_SEARCH_DATa = 'SET_LIST_SEARCH_DATa'

const state = {
  search:null,
  listSearchData:null
}


const mutations = {
  [SET_POLICY_SEARCH]: (state, value) => {
    state.search= value
  },
  [SET_LIST_SEARCH_DATa]: (state, value) => {
    state.listSearchData= value
  },
}


const actions = {
  setSearch({ commit },val){
    commit(SET_POLICY_SEARCH, val)
  },
  setListSearchData({ commit },val){
    commit(SET_LIST_SEARCH_DATa, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
