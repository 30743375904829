<template>
  <section class="input-number">
    <el-input
      v-model="minValue"
      placeholder="Min"
      @input="handleInput"
      @change="handleChange"
      @keyup.enter.native="handleEnter"></el-input>
    <span class="margin-right-ten">-</span>
    <el-input
      v-model="maxValue"
      placeholder="Max"
      @input="handleInput"
      @change="handleChange"
      @keyup.enter.native="handleEnter"></el-input>
  </section>
</template>

<script>
export default {
  name: 'vMinMax',
  props: {
    value: {
      type: [String, Number,Array],
      default: ()=>([])
    },
  },
  data(){
    return{
      minValue:'',
      maxValue:'',

    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if(val !== this.minValue) {
          if(this.checkValue(val) || this.checkValue(this.minValue)) {
            this.minValue = val[0]
            this.maxValue = val[1]
          }

        }
      }
    }
  },
  methods:{
    checkValue(value) {
      if(value === '' || value === null) {
        return false
      }
      return true
    },
    handleInput(event) {
      this.$emit('input', [this.minValue,this.maxValue])
    },
    handleChange(){
      this.$emit('change')
    },
    handleEnter() {
      this.$emit('keyEnter')
    },
    handlerBlur() {
      this.$emit('blur')
    },
  }
}
</script>

<style scoped lang="scss">
  .input-number{
    display: flex;
    align-items: center;
    span{
      margin: 0 10px;
    }
  }
</style>
