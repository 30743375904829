<template>
  <el-input
    v-model="inputValue"
    :type="type"
    :maxlength="maxlength"
    :minlength="minlength"
    :placeholder="placeholder"
    :readonly="readonly"
    :disabled="disabled"
    :suffix-icon="suffixIcon"
    :rows="rows"
    size="small"
    :class="{ 'no-border': noBorder }"
    oninput ="this.type=='number'?(value=value.replace(/[^0-9.]/g,'')):''"
    @input="handleInput"
    @change="handlerChange"
    @keyup.enter.native="handleEnter"
    @blur="handlerBlur"
  >
    <template
      v-if="prepend"
      slot="prepend"
    >
      {{ prepend }}
    </template>
    <template
      v-if="suffix"
      slot="suffix"
    >
      <i
        v-if="deleteIcon&&inputValue"
        :class="deleteIcon"
        class="mg-r-3"
        @click="hanlderDel"></i>
      <i
        :class="suffix"
        @click="hanlderSearch"></i>
    </template>
    <template
      v-if="append"
      slot="append"

    >
      <span
        class="hand"
        @click="appendEmit">
        {{ append }}
      </span>

    </template>
  </el-input>
</template>
<script>
export default {
  name: 'VInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    minlength: {
      type: [String, Number],
      default: ''
    },
    rows: {
      type: Number,
      default: 2
    },
    readonly: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    deleteIcon: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    suffixIcon: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    max: {
      type: [Number, Object],
      default: null
    },
    min: {
      type: [Number, Object],
      default: null
    },
    int: {
      type: Boolean,
      default: false
    },
    keyNumber: {
      type: Boolean,
      default: false
    },
    isUpper: {
      type: Boolean,
      default: false
    },
    money: {
      type: Boolean,
      default: false
    },
    negativeMoney:{
      // 价钱可以输入负数
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      inputValue: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if(val !== this.inputValue) {
          if(this.checkValue(val) || this.checkValue(this.inputValue)) {
            this.inputValue = val
          }

        }
      }
    },
    inputValue: {
      immediate: true,
      handler(val) {
        if(this.isUpper){
          val = val.toLocaleUpperCase()
        }
        if(val == null || val === '') {
          this.$emit('input', '')
        }
        else {
          let currentValue = val
          if(this.keyNumber) {
            const pat = /[0-9]/g
            const patResult = currentValue.match(pat)
            currentValue = patResult? patResult.join(''): ''
          }
          if(typeof this.min == 'number' && Number(currentValue) < this.min) {
            currentValue = this.min
          }
          if(typeof this.max == 'number' && Number(currentValue) > this.max) {
            currentValue = this.max
          }
          if(this.int && val !== 0 && val !== '0' && val.indexOf(0) == 0) {
            currentValue = String(currentValue).replace(/\b(0+)/gi, '')
          }
          if(this.money){
            // 只能输入数字和小数点
            currentValue=currentValue.toString().replace(/[^\d.]/g, '')

            // 去掉多余小数点
            currentValue=currentValue.toString().replace(/\.{2,}/g, '.')
            // 不能以00开头
            currentValue=currentValue.toString().replace(/^00/, '0.')
            // 小数点开头自动补0
            currentValue=currentValue.toString().replace(/^\./g, '0.')
            // 小数点开头自动补0
            const regex = /\./g
            const match = currentValue.match(regex)
            if (match && match.length > 1) {
              currentValue = currentValue.slice(0, -1)
            }
            // 保留两位小数
            currentValue=currentValue.toString().replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
          }
          if(currentValue !== val) {
            this.inputValue = currentValue
          }
          this.$emit('input', currentValue)
        }
      }
    }
  },
  methods: {
    handleInput(event) {
      // this.$emit('input', event)
      this.$emit('handlerChange',this.inputValue)
    },
    handleEnter() {
      this.$emit('keyEnter')
    },
    handlerBlur() {
      this.$emit('blur')
    },
    handlerChange() {
      this.$emit('change',this.inputValue)
      // this.$emit('input',this.inputValue)
    },
    checkValue(value) {
      if(value === '' || value === null) {
        return false
      }
      return true
    },
    appendEmit(){
      this.$emit('handlerAppend')
    },
    hanlderDel(){
      this.$emit('input', '')
      this.$emit('hanlderSearch')
    },
    hanlderSearch(){
      this.$emit('hanlderSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-input {
    width: 100%;
    & /deep/ .el-input-group__prepend {
      padding: 0 10px;
    }
    &.no-border /deep/ .el-input__inner {
      border: 0!important;
    }
  }
</style>
<style lang="scss">
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
  .hand{
    cursor:pointer
  }
  .el-input__suffix{
    line-height: 32px;
  }
  .mg-r-3{
    margin-right: 3px;
  }
</style>
