<template>
  <el-upload
    ref="upload"
    action
    :multiple="multiple"
    :drag="drag"
    :on-remove="handleRemove"
    :disabled="disabled"
    :show-file-list="false"
    :limit="isLimit?limit:0"
    :http-request="customUpload"
    :file-list="fileList"
    :data="uploadData"
  >
    <template v-if="card">
      <div class="upload-card">
        <img
          v-if="localData.thumb_url"
          :src="localData.thumb_url"
          alt=""/>
        <div
          v-else
          class="add-icon"></div>
      </div>
    </template>
    <template v-else>
      <v-button
        ref="upload-button"
        :disabled="disabled"
        :plain="btnPlain"
        class="button-theme"
        :type="btnType">{{ label }}</v-button>
    </template>
    <slot></slot>
  </el-upload>
</template>
<script>
import { uploadOrderFile } from '@/api/order'
export default {
  name: 'VUpload',
  props: {
    otherData: {
      type: Object,
      default: () => ({})
    },
    uploadData: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Upload file'
    },
    card: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: Object,
      default: () => ({})
    },
    btnType: {
      type: String,
      default: 'primary'
    },
    btnPlain: {
      type: Boolean,
      default: false
    },
    direct: {
      type: Number,
      default: 1 // 只上傳不入庫
    },
    notUpload: {
      type: Boolean,
      default: false
    },
    limit:{
      type: Number,
      default: 1
    },
    multiple:{
      type: Boolean,
      default: true
    },
    isLimit:{
      type: Boolean,
      default: true
    }

  },
  data() {
    return {
      localData: {
        thumb_url: ''
      },
      fileList:[]
    }
  },
  watch: {
    fileData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.localData = Object.assign(this.localData, val)
      }
    }
  },
  methods: {
    clearFiles(){
      this.fileList.splice(0,1)
    },
    currentClick() {
      this.$refs['upload-button'].currentClick()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    // 自定義上傳
    customUpload(file) {
      let params = new FormData()
      params.append('file', file.file)
      // 直接上傳固定參數
      params.append('direct', this.direct) // 默認為 1
      if(this.direct == 0) {
        Object.keys(this.uploadData).forEach(key => {
          params.append(key, this.uploadData[key])
        })
      }

      // 圖片預覽鏈接
      let result = {
        url: URL.createObjectURL(file.file),
        isImage: file.file.type.indexOf('image') !== -1
      }
      if(this.notUpload) {
        this.$emit('uploadForm', {
          params,
          result,
          otherData: this.otherData
        })
      }
      else {
        this.network().uploadOrderFile(params, result)
      }

    //   console.log(result, '====')
    //   if(this.orderUpload) {
    //     this.network().uploadOrderFile(params, result)
    //   } else {
    //     this.$emit('upload', params, this.otherData, result)
    //   }
    },
    network() {
      return {
        uploadOrderFile: async (params, result) => {
          const { data } = await uploadOrderFile(params)
          const { url, isImage } = result
          if(isImage) {
            this.localData.thumb_url = url
          }
          const dataResult = {
            ...data,
            path: data.path,
            thumb_url: isImage? url: data.thumb_url // 使用本地圖片，避免不能即使獲取到遠程圖片問題
          }
          if(this.limit>1)
            this.fileList.push(dataResult)
          else{
            this.fileList = []
          }
          this.$emit('uploadSuccess', {
            data: dataResult,
            result,
            otherData: this.otherData
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-card {
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 348px;
  height: 200px;
  border: 1px dashed #91939A;
  border-radius: 4px;
  img {
    max-width: 100%;
    max-height: 100%;
    z-index: 3;
  }
  .add-icon {
    width: 100%;
    height: 100%;
    &::before {
    z-index: -1;
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 1px;
    background: #979797;
  }
  &::after {
    z-index: -1;
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 50px;
    background: #979797;
  }
  }
}
</style>
