
const SET_QUOTE_INFORMATION_FORM = 'SET_QUOTE_INFORMATION_FORM'
const SET_NCD = 'SET_NCD'
const SET_NCD_LIST = 'SET_NCD_LIST'
const SET_PRODUCT_ID = 'SET_PRODUCT_ID'
const SET_COMPANY_ID = 'SET_COMPANY_ID'

const state = {
  quoteInformationForm: null,
  ncd:null,
  ncdList:null,
  productID:null,
  companyID:null
}

const mutations = {
  [SET_NCD]: (state, value) => {
    state.ncd= value
  },
  [SET_COMPANY_ID]: (state, value) => {
    state.companyID= value
  },
  [SET_NCD_LIST]: (state, value) => {
    state.ncdList= value
  },

  [SET_QUOTE_INFORMATION_FORM]: (state, value) => {
    state.quoteInformationForm= value
  },
  [SET_PRODUCT_ID]: (state, value) => {
    state.productID= value
  },
  [SET_COMPANY_ID]: (state, value) => {
    state.companyID= value
  }
}

const limit = {
  time: '',
  value: ''
}

const actions = {
  setProductID({ commit }, val) {
    commit(SET_PRODUCT_ID, val)
  },
  setCompanyID({ commit }, val) {
    commit(SET_COMPANY_ID, val)
  },
  setQuoteInformationForm({ commit }, val) {
    commit(SET_QUOTE_INFORMATION_FORM, val)
  },
  setNcd({ commit }, val) {
    commit(SET_NCD, val)
  },
  setNcdList({ commit }, val) {
    commit(SET_NCD_LIST, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
