const SET_COVERAGE = 'SET_COVERAGE'
const SET_TOTAL_PREMIUM = 'SET_TOTAL_PREMIUM'
const SET_RISK_PREMIUM = 'SET_RISK_PREMIUM'
const SET_DOMESTIC = 'SET_DOMESTIC'
const SET_HOME_PREMIUM = 'SET_HOME_PREMIUM'
const SET_CONSTRUCTION_PREMIUM = 'SET_CONSTRUCTION_PREMIUM'
const SET_EC_PREMIUM = 'SET_EC_PREMIUM'

const state = {
  coverageData:null,
  totalPremium:null,
  riskPremium:null,
  domesticPremium:null,
  homePremium:null,
  constructionPremium:null,
  ecPremium:0
}


const mutations = {
  [SET_EC_PREMIUM]: (state, value) => {
    state.ecPremium= value
  },
  [SET_COVERAGE]: (state, value) => {
    state.coverageData= value
  },
  [SET_TOTAL_PREMIUM]: (state, value) => {
    state.totalPremium= value
  },
  [SET_RISK_PREMIUM]: (state, value) => {
    state.riskPremium= value
  },
  [SET_DOMESTIC]: (state, value) => {
    state.domesticPremium= value
  },
  [SET_HOME_PREMIUM]: (state, value) => {
    state.homePremium= value
  },
  [SET_CONSTRUCTION_PREMIUM]: (state, value) => {
    state.constructionPremium= value
  },
}


const actions = {
  setEcPremium({ commit },val){
    commit(SET_EC_PREMIUM, val)
  },
  setConstructionPremium({ commit },val){
    commit(SET_CONSTRUCTION_PREMIUM, val)
  },
  setCoverage({ commit },val){
    commit(SET_COVERAGE, val)
  },
  setPremium({ commit },val){
    commit(SET_TOTAL_PREMIUM, val)
  },
  setRiskPremium({ commit },val){
    commit(SET_RISK_PREMIUM, val)
  },
  setDomesticPremium({ commit },val){
    commit(SET_DOMESTIC, val)
  },
  setHomePremium({ commit },val){
    commit(SET_HOME_PREMIUM, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
