<template>
  <el-checkbox-group
    v-model="checkList"
    @change="changeValue">
    <el-checkbox
      v-for="(item, index) in list"
      :key="index"
      :disabled="disabled"

      :class="{ 'border-block': borderBlock, 'block': block }"
      :label="item[valueKey]">
      <slot :item="item">
        <span>{{ item[labelKey] }}</span>
      </slot>
      <slot
        :id="item[valueKey]"
        name="input"
        :index="index"></slot>
    </el-checkbox>
  </el-checkbox-group>
</template>
<script>
export default {
  name: 'VCheckboxGroup',
  props: {
    value: {
      type: Array,
      default:() => ([])
    },
    list: {
      type: Array,
      default: () => ([])
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    borderBlock: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkList: this.value||[]
    }
  },
  watch: {
    checkList: {
      // immediate: true,
      deep: true,
      handler(val) {
        if(val.join() !== this.value.join()) {
          this.$emit('input', val)
        }
      }
    },
    value: {
      deep: true,
      handler(val) {
        if(val.join() !== this.checkList.join()) {
          this.checkList = val
        }
      }
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-radio-group{
  .is-active {
    .el-radio-button__inner{
      color: white!important;
    }
  }
}
.el-checkbox-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .block {
    width: 100%;
  }
  .border-block {
    align-items: center;
    .el-checkbox__label {
      & > span {
        display: inline-block;
        padding: 0 10px;
        height: 32px;
        line-height: 30px;
        color: #076081;
        font-size: 12px;
        border: 1px solid #076081;
        border-radius: 4px;
        background: #ecf5ff;
      }
    }
  }
  ::v-deep .el-checkbox {
    position: relative;
    display: flex;
    // align-items: center;
    margin-right: 15px;
    margin-bottom: 5px;
    & /deep/ .el-checkbox__input {
      padding-top: 2px;
    }
    .el-checkbox__inner{

    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
      background:  #076081!important;
      border: 1px solid #076081!important;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label{
      color: #076081;
      font-weight: normal;
    }
  }
}
</style>