import Vue from 'vue'
import { mapState, mapActions } from 'vuex' 
// 僅用於存放全局數據/方法
Vue.mixin({
  filters: {

  },
  data() {
    return {
          
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  watch: {
    
  },
  methods: {
    changeLanguage() {
      const lang = localStorage.getItem('lang') === 'en-US' ? 'zh-HK' : 'en-US'
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      this.$vantLocale(lang)
    }
  }
})