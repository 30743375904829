export default [
  {
    path: '/member/insurer',
    name: 'insurer',
    component: () => import('@/views/member/insurer'),
    meta: {
      keepAlive: false,
      title:'Insurers'
    }
  },
  {
    path: '/member/clients',
    name: 'clients',
    component: () => import('@/views/member/clients'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/member/memberReferral',
    name: 'memberReferral',
    component: () => import('@/views/member/memberReferral'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/member/referral/statement/:id',
    name: 'referralStatement',
    component: () => import('@/views/member/referralStatement'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/member/insurer/statement/:companyId',
    name: 'insureStatement',
    component: () => import('@/views/member/insurerStatement'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/member/statement/list',
    name: 'settlementList',
    component: () => import('@/views/member/settlementList'),
    meta: {
      keepAlive: false,
    }
  },
]
